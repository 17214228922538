// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { get, findIndex } from 'lodash';
import AnimateIn from '../animate-in';

// Components
import DropDown from '../elements/dropdown';

// Styles
import './styles.scss';

function InfoTabs({ data, selectedTab, style, innerRef }) {
  const tabList = get(data, 'selectTab') || [];

  // Return null if tabs are not present
  if (!tabList.length) return null;

  // Tab state
  const [tabIndex, setTab] = useState(Number(selectedTab));

  // Anchored Section Index
  const [anchoredSectionIndex, setSectionIndex] = useState(0);

  // Dropdown options
  const options = Array.isArray(tabList)
    ? tabList.map(({ tabTitle }) => ({
        value: tabTitle,
        label: tabTitle,
      }))
    : [];

  // Callback for tab selection
  const onTabSelect = (option) => {
    const selectedIndex = findIndex(
      options,
      (eachOption) => eachOption.value === option.value
    );

    // Reset the anchored section on click
    setSectionIndex(0);

    setTab(selectedIndex);
  };

  // Selected tab content
  const selectedTabContent = tabList[tabIndex];

  return (
    <section className="info-section">
      {options.length ? (
        <DropDown
          options={options}
          defaultValue={options[tabIndex]}
          onSelect={(selectedOption) => onTabSelect(selectedOption)}
        />
      ) : null}
      <div className="wrapper">
        <div className="tabs-container">
          {Array.isArray(options) && options.length ? (
            <div className="tabs">
              {options.map((eachTab, index) => (
                <div
                  key={index}
                  onClick={() => onTabSelect(options[index])}
                  className={index === tabIndex ? 'active' : ''}
                  onKeyDown={() => onTabSelect(options[index])}
                  tabIndex={index}
                  role="button"
                >
                  {eachTab.label}
                </div>
              ))}
            </div>
          ) : null}
          <div className="content">
            <div
              className="tab-content"
              id={selectedTabContent.tabTitle}
              ref={innerRef}
              style={style}
            >
              {selectedTabContent.tabTitle ? (
                <h2>{selectedTabContent.tabTitle}</h2>
              ) : null}
              {selectedTabContent.infoDate ? (
                <span className="date">{selectedTabContent.infoDate}</span>
              ) : null}
              {Array.isArray(selectedTabContent.infoDetail)
                ? selectedTabContent.infoDetail.map((eachList) => (
                    <div
                      key={eachList.originalId}
                      className="rich-text"
                      id={`${eachList.originalId}`}
                    >
                      {eachList.sectionHeading ? (
                        <h3>{eachList.sectionHeading}</h3>
                      ) : null}
                      {eachList.sectionContent ? (
                        <p>{parse(eachList.sectionContent)}</p>
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="aside-navigation">
          <aside className={selectedTabContent.sectionHeading}>
            {selectedTabContent.infoAsideTitle ? (
              <h5>{selectedTabContent.infoAsideTitle}</h5>
            ) : null}
            <ul>
              {Array.isArray(selectedTabContent.infoDetail) &&
              selectedTabContent.infoDetail.length
                ? selectedTabContent.infoDetail.map((eachList, index) => (
                    <li
                      key={eachList.originalId}
                      className={anchoredSectionIndex === index ? 'active' : ''}
                    >
                      <a
                        href={`#${eachList.originalId}`}
                        title={eachList.sectionHeading}
                        onClick={() => {
                          setSectionIndex(index);
                        }}
                      >
                        {eachList.sectionHeading}
                      </a>
                    </li>
                  ))
                : null}
            </ul>
          </aside>
        </div>
      </div>
    </section>
  );
}

// Proptypes
InfoTabs.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedTab: PropTypes.string.isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(InfoTabs);
