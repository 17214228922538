import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';
import querySting from 'query-string';

// Component
import Hero from '../components/hero';
import Layout from '../components/layout';
import InfoTab from '../components/info-tabs';

// Utils
import SeoUtils from '../utils/seo-utils';

// Styles
import '../styles/pages/legal.scss';

const LegalPage = ({ data, ...props }) => {
  const [heroData] = get(data, 'allDatoCmsLegalInformationPage.nodes');

  const hero = {
    title: get(heroData, 'pageTitle'),
    description: get(heroData, 'bannerTitleAndDescription'),
  };

  const content = get(data, 'allDatoCmsLegalInformationPage.nodes[0].content');

  // Seo
  const seoTags = get(
    data,
    'allDatoCmsLegalInformationPage.nodes[0].seoMetaTags.tags'
  );
  const seoProps = {
    description: SeoUtils.extractProperty(seoTags, 'name', 'description'),
    twitterCardType: SeoUtils.extractProperty(seoTags, 'name', 'twitter:card'),
    cardImage: SeoUtils.extractProperty(seoTags, 'property', 'og:image'),
  };

  // Query params
  const queryParams = querySting.parse(get(props, 'location.search'));

  return (
    <Layout title={hero.title} seoProps={seoProps}>
      <Hero
        title={hero.title}
        description={hero.description}
        location={[
          { id: '1', value: 'Homepage', location: '/' },
          {
            id: '2',
            value: 'Legal Information',
            location: '/legal',
          },
        ]}
      />
      {content ? (
        <InfoTab data={content[0]} selectedTab={queryParams.tabIndex || '0'} />
      ) : null}
    </Layout>
  );
};

export default LegalPage;

// Proptype
LegalPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

// Query
export const infoQuery = graphql`
  query LegalQuery {
    allDatoCmsLegalInformationPage {
      nodes {
        seoMetaTags {
          tags
        }
        bannerBackgroundColor {
          hex
        }
        pageTitle
        bannerTitleAndDescription
        content {
          ... on DatoCmsAddInfoTab {
            id
            selectTab {
              tabTitle
              infoDate(formatString: "MMMM YYYY")
              infoDetail {
                sectionHeading
                sectionContent
                originalId
              }
              infoAsideTitle
            }
          }
          ... on DatoCmsContact {
            id
            contactList {
              contactPromoList {
                titleAndDescription
                callUsTitle
                contactUsNumber
                contactEmailField
                contactEmail
              }
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsSignPostCenteredList {
            id
            signPostCenteredList {
              id
              heading
              description
              ctaText
              ctaLink
            }
            model {
              apiKey
            }
          }
          ... on DatoCmsSignPostImageList {
            id
            signPostImageList {
              signTitle
              signSubTitle
              signDecription
              signCtaText
              signPostImageName
              model {
                apiKey
              }
            }
          }
          ... on DatoCmsFaqAccordion {
            id
            title
            faqList {
              faqList {
                id
                title
                body
              }
            }
          }
          ... on DatoCmsSignPostSalesList {
            id
            backgroundColor {
              hex
            }
            signPostSalesList {
              id
              salesTitle
              salesDescription
              readyToTalkCtaText
              readyToTalkCtaLink
              getInTouchTitle
              getInTouchDescription
              aboutUsTitle
              signUpDescription
              aboutUsCtaText
              aboutUsLink
              callUsTitle
              callUsNumber
              sendUsMessage
            }
            model {
              apiKey
            }
          }
        }
      }
    }
  }
`;
